<template>
  <section class="page-workplace fs-page">
    <div class="fs-page-main">
      <div class="wp-hd">
        <div class="wp-opts-item">
          <div class="wp-opts-num">15</div>
          <div class="wp-opts-title">待付款</div>
        </div>
        <div class="wp-opts-item">
          <div class="wp-opts-num">46</div>
          <div class="wp-opts-title">待发货</div>
        </div>
        <div class="wp-opts-item">
          <div class="wp-opts-num">2</div>
          <div class="wp-opts-title">退款售后</div>
        </div>
        <div class="wp-opts-item">
          <div class="wp-opts-num">3</div>
          <div class="wp-opts-title">待评价</div>
        </div>
        <div class="wp-opts-item">
          <div class="wp-opts-num color-red">5</div>
          <div class="wp-opts-title">物流异常</div>
        </div>
        <div class="wp-opts-item">
          <div class="wp-opts-num color-gray">0</div>
          <div class="wp-opts-title">违规提醒</div>
        </div>
        <div class="wp-opts-item">
          <div class="wp-opts-num">1</div>
          <div class="wp-opts-title">待处理订单</div>
        </div>
      </div>
      <div class="wp-bd">
        <div class="wp-module">
          <div class="wp-m-title">我的看板</div>
          <div class="wp-m-content my-bank-container">
            <div class="my-bank-container-left ov">
              <div class="ov-opts-item">
                <div class="ov-opts-title">访客数</div>
                <div class="ov-opts-num">23548</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-up">8%</span>
                </div>
              </div>

              <div class="ov-opts-item">
                <div class="ov-opts-title">支付买家数</div>
                <div class="ov-opts-num">9820</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-down">8%</span>
                </div>
              </div>
              <div class="ov-opts-item">
                <div class="ov-opts-title">支付订单数</div>
                <div class="ov-opts-num">12680</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-down">6%</span>
                </div>
              </div>
              <div class="ov-opts-item">
                <div class="ov-opts-title">支付金额</div>
                <div class="ov-opts-num">234556</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-up">8%</span>
                </div>
              </div>
            </div>
            <div class="my-bank-container-right">
              <v-chart :options="chartOptions" autoresize />
            </div>
          </div>
        </div>
      </div>
      <div class="wp-ft">
        <div class="wp-ft-left">
          <div class="wp-module">
            <div class="wp-m-title">交易概况</div>
            <div class="wp-m-content trade-wrapper">
              <div class="trade-main">
                <div class="block-1-1 trade-block trade-block-light">
                  <div class="trade-block-item">
                    <div class="trade-block-title">访客人数</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                </div>
                <div class="block-1-2 trade-block trade-block-dark">
                  <div class="trade-block-item">
                    <div class="trade-block-title">访客人数</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                </div>

                <div class="block-2-1 trade-block trade-block-light">
                  <div class="trade-block-item">
                    <div class="trade-block-title">下单人数</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                </div>
                <div class="block-2-2 trade-block trade-block-dark">
                  <div class="trade-block-item">
                    <div class="trade-block-title">下单买家数</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                  <div class="trade-block-item">
                    <div class="trade-block-title">下单金额</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                </div>

                <div class="block-3-1 trade-block trade-block-light">
                  <div class="trade-block-item">
                    <div class="trade-block-title">支付人数</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                </div>
                <div class="block-3-2 trade-block trade-block-dark">
                  <div class="trade-block-item">
                    <div class="trade-block-title">支付买家数</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                  <div class="trade-block-item">
                    <div class="trade-block-title">支付金额</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                  <div class="trade-block-item">
                    <div class="trade-block-title">客单价</div>
                    <div class="trade-block-num">23546</div>
                  </div>
                </div>

                <div class="ex-text-block-1 ex-text-block">
                  <div class="ex-text-block-title">下单转换率</div>
                  <div class="ex-text-block-num">36%</div>
                </div>
                <div class="ex-text-block-2 ex-text-block">
                  <div class="ex-text-block-title">
                    下单支付
                    <br />
                    转换率
                  </div>
                  <div class="ex-text-block-num">36%</div>
                </div>
                <div class="ex-text-block-3 ex-text-block">
                  <div class="ex-text-block-title">
                    访客支付
                    <br />
                    转换率
                  </div>
                  <div class="ex-text-block-num">36%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="wp-ft-right">
          <div class="wp-module">
            <div class="wp-m-title">用户运营</div>
            <div class="wp-m-content ov">
              <div class="ov-opts-item">
                <div class="ov-opts-title">群活跃人数</div>
                <div class="ov-opts-num">23548</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-up">8%</span>
                </div>
              </div>

              <div class="ov-opts-item">
                <div class="ov-opts-title">消息发送条数</div>
                <div class="ov-opts-num">9820</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-down">8%</span>
                </div>
              </div>
              <div class="ov-opts-item">
                <div class="ov-opts-title">引导浏览人数</div>
                <div class="ov-opts-num">12680</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-down">6%</span>
                </div>
              </div>
              <div class="ov-opts-item">
                <div class="ov-opts-title">引导成交人数</div>
                <div class="ov-opts-num">234556</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-up">8%</span>
                </div>
              </div>
              <div class="ov-opts-item">
                <div class="ov-opts-title">引导成交金额</div>
                <div class="ov-opts-num">12680</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-down">6%</span>
                </div>
              </div>
              <div class="ov-opts-item">
                <div class="ov-opts-title">引导客单价</div>
                <div class="ov-opts-num">234556</div>
                <div class="ov-opts-comp">
                  较前日：
                  <span class="status-up">8%</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ECharts from 'vue-echarts'
import 'echarts/lib/chart/line'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/grid'
export default {
  name: 'Workplace',
  components: {
    'v-chart': ECharts,
  },
  data() {
    const data = []

    for (let i = 0; i <= 360; i++) {
      const t = (i / 180) * Math.PI
      const r = Math.sin(2 * t) * Math.cos(2 * t)
      data.push([r, i])
    }

    return {
      chartOptions: {
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#6a7985',
            },
          },
        },
        legend: {
          data: ['访客量', '支付买家数', '支付订单数'],
          right: '3%',
        },
        grid: {
          left: '3%',
          right: '3%',
          bottom: '3%',
          // show: true,
          containLabel: true,
        },
        xAxis: [
          {
            type: 'category',
            boundaryGap: false,
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },
            axisLabel: {
              color: '#999999',
              fontSize: '9',
            },

            data: [
              {
                value: '0:00',
                textStyle: {
                  align: 'left',
                },
              },
              '2:00',
              '4:00',
              '6:00',
              '8:00',
              '10:00',
              '12:00',
              '14:00',
              '16:00',
              '18:00',
              '20:00',
              '22:00',
              {
                value: '24:00',
                textStyle: {
                  align: 'right',
                },
              },
            ],
          },
        ],
        yAxis: [
          {
            type: 'value',
            axisLine: {
              show: false,
            },
            axisTick: {
              show: false,
            },

            axisLabel: {
              color: '#999999',
            },
          },
        ],
        series: [
          {
            name: '访客量',
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#DFDCFF', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(236, 234, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
            lineStyle: {
              color: '#7E58FF',
            },
            itemStyle: {
              color: '#7E58FF',
            },
            smooth: true,
            data: [120, 132, 101, 134, 90, 230, 210, 132, 101, 134, 90, 230, 210],
          },
          {
            name: '支付买家数',
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#C6DBFF', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(198, 219, 255, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
            lineStyle: {
              color: '#4189FF',
            },
            itemStyle: {
              color: '#4189FF',
            },
            smooth: true,

            data: [220, 182, 191, 234, 290, 330, 310, 220, 182, 191, 234, 290, 330],
          },
          {
            name: '支付订单数',
            type: 'line',
            symbol: 'circle',
            showSymbol: false,
            areaStyle: {
              color: {
                type: 'linear',
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: '#CEFFE8', // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: 'rgba(206, 255, 232, 0)', // 100% 处的颜色
                  },
                ],
              },
            },
            lineStyle: {
              color: '#63DABE',
            },
            itemStyle: {
              color: '#63DABE',
            },
            // label: {
            //   normal: {
            //     show: true,
            //     position: 'top'
            //   }
            // },
            smooth: true,
            data: [820, 932, 901, 934, 1290, 1330, 1320, 210, 260, 100, 305, 684, 100],
          },
        ],
      },
    }
  },
  computed: {},

  mounted() {},
  methods: {},
}
</script>

<style lang="less" scoped>
.page-workplace {
  .fs-page-main {
    display: flex;
    flex-direction: column;
  }
  .wp-hd {
    background: #fff;
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    .wp-opts-item {
      width: 100px;
      text-align: center;
      .wp-opts-num {
        font-family: DIN-Light;
        font-weight: 700;
        letter-spacing: -0.015em;
        font-size: 30px;
        color: @primary-color;
        &.color-red {
          color: #ff3c11;
        }
        &.color-gray {
          color: #999;
        }
      }
      .wp-opts-item {
        color: #333;
      }
    }
  }
  .wp-bd {
    background: #fff;
    height: 340px;
    margin-top: 16px;
    .wp-m-content {
      height: 280px;
    }
    .my-bank-container {
      display: flex;
      position: relative;
      &:after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: #eee;
        left: 46%;
        top: 0;
        transform: translateX(-50%);
      }
      .my-bank-container-left {
        width: 46%;
      }
      .my-bank-container-right {
        width: 54%;
        height: 100%;
        overflow: hidden;
      }
    }
  }
  .wp-ft {
    height: 400px;
    margin-top: 16px;
    display: flex;
    .wp-m-content {
      height: 300px;
    }
    .wp-ft-left {
      width: 50%;
      background: #fff;
      margin-right: 8px;
      .trade-wrapper {
        height: 300px;
        background: url('/img/workplace/trade_wrapper.png') no-repeat left center / auto 220px;
        display: flex;
        align-items: center;
      }
      .trade-main {
        position: relative;
        height: 100%;
        width: 100%;
        @trade-w: 410px;
        @trade-start-w: 218px;
        @trade-d-w: 20px;

        @trade-h: 68px;
        @trade-start-h: 40px;
        @trade-d-h: 8px;
        .trade-block {
          display: flex;
          justify-content: space-around;
          align-items: center;

          .trade-block-item {
            text-align: center;
            width: 0;
            flex: 1;
          }
          .trade-block-title {
            font-size: 14px;
            line-height: 1;
          }
          .trade-block-num {
            font-family: DIN-Light;
            font-weight: 700;
            letter-spacing: -0.015em;
            font-size: 26px;
            line-height: 1;
            padding-top: 8px;
          }
          &.trade-block-light {
            .trade-block-title {
              color: #fff;
            }
            .trade-block-num {
              color: #392d5c;
            }
          }
          &.trade-block-dark {
            .trade-block-title {
              color: #949494;
            }
            .trade-block-num {
              color: #666;
              font-size: 20px;
            }
          }
        }
        .ex-text-block {
          line-height: 1;
          display: flex;
          flex-direction: column;
          justify-content: center;
          .ex-text-block-title {
            font-size: 12px;
            color: #666;
          }
          .ex-text-block-num {
            padding-top: 4px;
            font-family: DIN-Light;
            font-weight: 700;
            letter-spacing: -0.015em;
            font-size: 20px;
            color: #333;
          }
        }
        .block-1-1 {
          width: @trade-start-w;
          height: @trade-h;
          position: absolute;
          top: @trade-start-h;
          left: 0;
        }
        .block-1-2 {
          width: @trade-w - @trade-start-w;
          height: @trade-h;
          position: absolute;
          top: @trade-start-h;
          left: @trade-start-w;
        }

        .block-2-1 {
          width: @trade-start-w - 2 * @trade-d-w;
          height: @trade-h;
          position: absolute;
          top: @trade-start-h + @trade-h + @trade-d-h;
          left: @trade-d-w;
        }
        .block-2-2 {
          width: @trade-w - @trade-start-w + @trade-d-w;
          height: @trade-h;
          position: absolute;
          top: @trade-start-h + @trade-h + @trade-d-h;
          left: @trade-start-w - @trade-d-w;
        }

        .block-3-1 {
          width: @trade-start-w - 4 * @trade-d-w;
          height: @trade-h;
          position: absolute;
          top: @trade-start-h + (@trade-h + @trade-d-h) * 2;
          left: @trade-d-w*2;
        }
        .block-3-2 {
          width: @trade-w - @trade-start-w + 2 * @trade-d-w;
          height: @trade-h;
          position: absolute;
          top: @trade-start-h + (@trade-h + @trade-d-h) * 2;
          left: @trade-start-w - 2 * @trade-d-w;
        }
        .ex-text-block-1 {
          position: absolute;
          width: 70px;
          height: 50px;
          left: 450px;
          top: 80px;
        }
        .ex-text-block-2 {
          position: absolute;
          width: 70px;
          height: 50px;
          left: 450px;
          top: 170px;
        }
        .ex-text-block-3 {
          position: absolute;
          width: 70px;
          height: 50px;
          left: 540px;
          top: 125px;
        }
      }
      @media screen and (max-width: 1600px) {
        .trade-wrapper {
          background: url('/img/workplace/trade_wrapper.png') no-repeat left center / auto 154px;
        }
        .trade-main {
          transform: scale(0.7);
          transform-origin: left center;
        }
      }
    }

    .wp-ft-right {
      width: 50%;
      background: #fff;
      margin-left: 8px;
      .ov {
        padding-top: 40px;
      }
    }
  }
  .wp-module {
    padding: 20px 30px;
    .wp-m-title {
      font-size: 20px;
      font-weight: 600;
      color: #000;
      line-height: 1;
    }
  }
  .ov {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    align-content: center;
    .ov-opts-item {
      color: #333;
      width: 25%;
      text-align: center;
      margin: 30px 0;
      .ov-opts-num {
        font-family: DIN-Light;
        font-weight: 700;
        letter-spacing: -0.015em;
        font-size: 30px;
        line-height: 1;
        padding: 14px 0;
      }
      .ov-opts-comp {
        color: #999;
        .status-up {
          color: #ff3c11;
          &:after {
            content: '↑';
            font-size: 120%;
            margin-left: 2px;
          }
          //→↓
        }
        .status-down {
          color: #24b08a;
          &:after {
            content: '↑';
            font-size: 120%;
            margin-left: 2px;
          }
        }
      }
    }
  }
  .echarts {
    width: 100%;
    height: 100%;
  }
}
</style>
