import { render, staticRenderFns } from "./Workplace.vue?vue&type=template&id=64a70079&scoped=true&"
import script from "./Workplace.vue?vue&type=script&lang=js&"
export * from "./Workplace.vue?vue&type=script&lang=js&"
import style0 from "./Workplace.vue?vue&type=style&index=0&id=64a70079&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64a70079",
  null
  
)

export default component.exports